import React from 'react'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import { PageContext } from 'src/page/types'
import { graphql } from 'gatsby'
import {
    unwrapServiceFromQueryAndSetLangPath,
    WrappedServiceQueryResult
} from 'src/model/serviceQuery'
import { ServicesGrid } from 'src/components/ServicesGrid'
import Container from 'src/components/Container'
import CallToAction from 'src/components/CallToAction'
import { css } from 'src/style/css'
import { useTheme } from '@emotion/react'

type ServicesPageProps = {
    data: {
        services: WrappedServiceQueryResult
    }
    pageContext: PageContext
}

const Info = ({ text, list }: { text: string; list: string[] }) => {
    const th = useTheme()
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}
        >
            <p
                css={css`
                    margin-bottom: ${th.spacing.md};
                    font-size: ${th.fontSize.md};
                    text-align: justify;
                    font-weight: ${th.fontWeight.thin};
                `}
            >
                {text}
            </p>
            <ul
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    list-style: disc;
                    max-width: 580px;
                    padding-left: ${th.spacing.md};
                `}
            >
                {list.map((x, idx) => (
                    <li
                        key={idx}
                        css={css`
                            font-size: ${th.fontSize.md};
                            &:nth-of-type(odd) {
                                width: 65%;
                            }
                            &:nth-of-type(even) {
                                width: 35%;
                            }
                            @media (max-width: ${th.mediaQuery.sm}) {
                                width: 100% !important;
                            }
                            color: ${th.colors.darkGray};
                            line-height: 1.8;
                            font-weight: ${th.fontWeight.thin};
                        `}
                    >
                        {x}
                    </li>
                ))}
            </ul>
        </div>
    )
}
const ServicesPage = ({ pageContext, data }: ServicesPageProps) => {
    const { msgs, lang } = pageContext
    const allServices = unwrapServiceFromQueryAndSetLangPath(
        data.services,
        lang
    )

    return (
        <Layout pageCtx={pageContext}>
            <PageHeader>{t(msgs.services.title)}</PageHeader>
            <Container>
                <Info
                    text={msgs.servicesPage.introText}
                    list={msgs.servicesPage.countriesList}
                />
                <ServicesGrid items={allServices} msgs={msgs} />
                <Info
                    text={msgs.servicesPage.areasOfProjectsText}
                    list={msgs.servicesPage.areasOfProjectsList}
                />
            </Container>
            <CallToAction
                text={t(msgs.services.callToAction.text)}
                linkText={t(msgs.services.callToAction.linkText)}
            />
        </Layout>
    )
}
export default ServicesPage

export const pageQuery = graphql`
    query ServicePageData {
        services: serviceOrderJson {
            ...Services
        }
    }
`
